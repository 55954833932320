<template>
    <div class="filter-box">
        <div class="title">{{ $t('assets.filter.filter_data') }}</div>
        <div class="filter-items">
            <div class="filter">
                <label>{{ $t('user.filters.search_by') }}</label>
                <v-autocomplete
                    :class="{
                        filtered:
                            currentFilters.location_id &&
                            typeof currentFilters.location_id !== 'undefined' &&
                            Object.values(currentFilters.location_id).length > 0,
                        changed:
                            JSON.stringify(currentFilters.location_id) != JSON.stringify(activeFilters.location_id) &&
                            Object.values(currentFilters.location_id).length > 0
                    }"
                    hide-details
                    class="select"
                    v-model="selectedLocationId"
                    v-if="Object.values(optionsLocations)"
                    :items="Object.values(optionsLocations)"
                    item-text="name"
                    item-value="id"
                    attach
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags
                    :key="keyLocationFilter"
                    :tags="[currentFilters.location_id]"
                    :backImage="'location_inactive_s30.svg'"
                    :nameTag="'name'"
                    value="id"
                ></Tags>
            </div>

            <!-- TYPE text -->
            <div class="filter">
                <label>{{ $t('notes.search') }}</label>
                <input
                    :class="{
                        filtered: currentFilters.name && Object.values(currentFilters.name).length > 0,
                        changed: currentFilters.name && currentFilters.name != activeFilters.name && Object.values(currentFilters.name).length > 0
                    }"
                    hide-details
                    class="input"
                    v-model.trim="currentFilters.name"
                    type="text"
                    @keyup.enter="applyFilters()"
                />
            </div>

            <!-- TYPE STATE -->
            <div class="filter">
                <label>{{ $t('product.assigned_states') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.state && Object.values(currentFilters.state).length > 0,
                        changed: JSON.stringify(currentFilters.state) != JSON.stringify(activeFilters.state)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.state"
                    :items="Object.values(optionsStates)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.state" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
            <!-- TYPE CATEGORY -->
            <div class="filter">
                <label>{{ $t('product.assigned_categories') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.categories && Object.values(currentFilters.categories).length > 0,
                        changed: JSON.stringify(currentFilters.categories) != JSON.stringify(activeFilters.categories)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.categories"
                    :items="Object.values(optionsCategories)"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    :placeholder="$t('assets.filter.filter_by')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.categories" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>

            <div class="filter date">
                <label>{{ $t('supervise.tracebility.table_headers.sort_type') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.sort_type,
                        changed: currentFilters.sort_type != activeFilters.sort_type
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.sort_type"
                    :items="optionsSortType"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                ></v-select>
            </div>

            <!-- TYPE DATE -->
            <div class="filter date">
                <label>{{ $t('supervise.checklists.table_headers.date') }}</label>
                <v-select
                    :class="{
                        filtered: currentFilters.type_date,
                        changed: JSON.stringify(currentFilters.type_date) != JSON.stringify(activeFilters.type_date)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.type_date"
                    :items="optionsDate"
                    item-text="name"
                    item-value="value"
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    @change="currentFilters.type_date == 'custom' ? (currentFilters.date = {}) : true"
                ></v-select>

                <div class="custom" v-if="currentFilters.type_date == 'custom'">
                    <div class="description">{{ $t('assets.filter.from') }}:</div>
                    <input class="date-input start" v-model="currentFilters.date.start_date" :max="minDate" type="date" @change="calcDate()" />
                    <div class="separator"></div>
                    <div class="description">{{ $t('assets.filter.to') }}:</div>
                    <input class="date-input end" v-model="currentFilters.date.end_date" :min="maxDate" type="date" @change="calcDate()" />
                </div>
            </div>

            <!-- TYPE FILTER -->
            <div v-if="toolTypeAvaible" class="filter">
                <label>{{ $t('timers_product.title') }}</label>
                <v-autocomplete
                    :class="{
                        filtered: currentFilters.types && Object.values(currentFilters.types).length > 0,
                        changed: JSON.stringify(currentFilters.types) != JSON.stringify(activeFilters.types)
                    }"
                    hide-details
                    class="select"
                    v-model="currentFilters.types"
                    :items="Object.values(typesFilters)"
                    :placeholder="$t('assets.filter.filter_by')"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    :no-data-text="$t('supervise.filter.no_results')"
                    return-object
                >
                    <template v-slot:selection="{ item, index }">
                        {{ index == 0 ? '' : '' }}
                    </template>
                </v-autocomplete>
                <Tags :tags="currentFilters.types" :nameTag="'name'" value="id" :removable="true"></Tags>
            </div>
        </div>

        <FilterActions
            :showClearFiltersButton="showClearFiltersButton"
            :clearFiltersCallback="resetFilters"
            :filterCallback="applyFilters"
            :changesDetected="changesDetected"
        />
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import FilterActions from '../../../ui/FilterActions.vue'

export default {
    name: 'FilterLabel',
    components: {
        Tags,
        FilterActions
    },
    props: {
        isDisabled: { type: Boolean, default: false },
        applyFilter: { type: Boolean, default: false },
        pagination: { type: Object, default: false },
        sortBy: { type: undefined, default: false },
        sortType: { type: undefined, default: false },
        changePagination: { type: Boolean, default: false },
        toolTypeAvaible: {type: Boolean, default: false}
    },
    data() {
        return {
            currentFilters: {
                sort_type: 'expiration_date'
            },
            emptyFilters: {
                type_date: 'today',
                sort_type: 'expiration_date'
            },
            activeFilters: {},

            // Filter variables
            changesDetected: false,
            maxDate: true,
            minDate: true,
            keyLocationFilter: 1,
            selectedLocationId: false,
            typesFilters: [
                {
                    id: 1,
                    name: i18n.t('timers_product.label')
                },
                {
                    id: 2,
                    name: i18n.t('timers_product.digitalTimer')
                },
                {
                    id: 3,
                    name: i18n.t('timers_product.both')
                }
            ]
        }
    },
    computed: {
        filters() {
            return this.$store.getters['label/getFiltersActive']
        },
        optionsLocations() {
            return this.$store.getters['label/getFilterLocation']()
        },
        optionsStates() {
            return this.$store.getters['label/getStatesForm']()
        },
        optionsCategories() {
            return this.$store.getters['label/getCategoriesForm']()
        },
        optionsDate() {
            return [
                { value: 'today', name: i18n.t('supervise.filter.today') },
                { value: 'yesterday', name: i18n.t('supervise.filter.yesterday') },
                { value: 'week', name: i18n.t('supervise.filter.week') },
                { value: 'month', name: i18n.t('supervise.filter.month') },
                { value: 'quarter', name: i18n.t('supervise.filter.quarter') },
                { value: 'custom', name: i18n.t('supervise.filter.custom') }
            ]
        },
        optionsSortType() {
            return [
                { value: 'expiration_date', name: i18n.t('label.preview.expiration_date') },
                { value: 'print_date', name: i18n.t('label.preview.print_date') }
            ]
        },

        showClearFiltersButton() {
            return JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)
        }
    },
    methods: {
        refreshSearch() {
            delete this.currentFilters.location_id
            delete this.currentFilters.groups
        },
        updateFilters() {
            this.$emit('applyFilters', this.allFilters)
        },
        calcDate() {
            this.currentFilters.date && this.currentFilters.date.start_date
                ? (this.maxDate = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD'))
                : true
            this.currentFilters.date && this.currentFilters.date.end_date
                ? (this.minDate = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD'))
                : true
            // Force update object
            this.currentFilters = { ...this.currentFilters }
        },

        applyFilters() {
            this.$overlay.loading()

            const iApplyFilters = this.changesDetected ? 1 : 0
            let pagenum = this.pagination.page

            if (iApplyFilters) {
                this.$emit('resetPagination')
                pagenum = 1
            }

            let definitiveFilters = {}

            if (this.currentFilters.location_id) {
                definitiveFilters.location_id = this.currentFilters.location_id
            } else definitiveFilters.location_id = ''

            definitiveFilters.name = this.currentFilters.name ? this.currentFilters.name : ''

            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            definitiveFilters.sort_type = this.currentFilters.sort_type

            if (this.currentFilters.categories && Object.values(this.currentFilters.categories).length) {
                var filter = this.currentFilters.categories
                definitiveFilters.categories = []
                for (var index in filter) {
                    definitiveFilters.categories.push(filter[index].id)
                }
                definitiveFilters.categories = definitiveFilters.categories.toString()
            } else definitiveFilters.categories = ''

            if (this.currentFilters.types) {
                var filter = this.currentFilters.types
                definitiveFilters.types = []
                for (var index in filter) {
                    definitiveFilters.types.push(filter[index].id)
                }
                definitiveFilters.types = definitiveFilters.types.toString()
            } else {
                definitiveFilters.types = ''
            }

            var self = this
            this.changesDetected = false

            this.$store.commit('label/setFiltersActive', this.currentFilters)
            this.$store.commit('label/setTracebilityFilters', {
                location_id: definitiveFilters.location_id,
                name: definitiveFilters.name,
                states: definitiveFilters.state,
                start_date: definitiveFilters.start_date,
                end_date: definitiveFilters.end_date,
                sort_type: definitiveFilters.sort_type,
                category: definitiveFilters.categories,
                types: definitiveFilters.types
            })
            this.$store
                .dispatch('label/loadTracebilityList', {
                    location_id: definitiveFilters.location_id ? definitiveFilters.location_id.id : '',
                    name: definitiveFilters.name,
                    states: definitiveFilters.state,
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    sort_type: definitiveFilters.sort_type,
                    number_pages: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType,
                    category: definitiveFilters.categories,
                    types: definitiveFilters.types
                })
                .then(function () {
                    // self.activeFilters = { ...self.currentFilters };
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    self.$emit('filtersApplied')
                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
        },
        resetFilters() {
            this.$emit('resetPagination')
            const iApplyFilters = 0
            var self = this
            this.$overlay.loading()
            // get first available location
            let locations = this.$store.getters['label/getFilterLocation']()
            // get first object of object
            let firstLocation = Object.values(locations)[0]
            this.currentFilters.location_id = firstLocation
            this.activeFilters.location_id = firstLocation
            this.emptyFilters.location_id = firstLocation
            this.selectedLocationId = firstLocation
            this.currentFilters = JSON.parse(JSON.stringify(this.emptyFilters))
            this.activeFilters = JSON.parse(JSON.stringify(this.emptyFilters))

            let definitiveFilters = {}

            if (this.currentFilters.location_id) {
                definitiveFilters.location_id = this.currentFilters.location_id
            } else definitiveFilters.location_id = ''

            this.changesDetected = false
            this.$store.commit('label/setFiltersActive', this.currentFilters)
            this.$store
                .dispatch('label/loadTracebilityList', {
                    start_date: 'today',
                    location_id: definitiveFilters.location_id ? definitiveFilters.location_id.id : ''
                })
                .then(function () {
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                })
        },
        handleChangePagination(page) {
            this.$overlay.loading()

            let self = this

            let pagenum = page ? page : this.pagination.page

            let definitiveFilters = {}

            if (this.currentFilters.location_id) {
                definitiveFilters.location_id = this.currentFilters.location_id
            } else definitiveFilters.location_id = ''

            definitiveFilters.name = this.currentFilters.name ? this.currentFilters.name : ''

            if (this.currentFilters.state && Object.values(this.currentFilters.state).length) {
                var filter = this.currentFilters.state
                definitiveFilters.state = []
                for (var index in filter) {
                    definitiveFilters.state.push(filter[index].id)
                }
                definitiveFilters.state = definitiveFilters.state.toString()
            } else definitiveFilters.state = ''

            if (this.currentFilters.type_date) {
                if (this.currentFilters.type_date != 'custom') {
                    definitiveFilters.start_date = this.currentFilters.type_date
                } else {
                    definitiveFilters.start_date = moment(this.currentFilters.date.start_date).format('YYYY-MM-DD')
                    definitiveFilters.end_date = moment(this.currentFilters.date.end_date).format('YYYY-MM-DD')
                }
            } else {
                definitiveFilters.start_date = ''
                definitiveFilters.end_date = ''
            }

            definitiveFilters.sort_type = this.currentFilters.sort_type

            if (this.currentFilters.categories && Object.values(this.currentFilters.categories).length) {
                var filter = this.currentFilters.categories
                definitiveFilters.categories = []
                for (var index in filter) {
                    definitiveFilters.categories.push(filter[index].id)
                }
                definitiveFilters.categories = definitiveFilters.categories.toString()
            } else definitiveFilters.categories = ''

            this.changesDetected = false

            this.$store
                .dispatch('label/loadTracebilityList', {
                    location_id: definitiveFilters.location_id ? definitiveFilters.location_id.id : '',
                    name: definitiveFilters.name,
                    states: definitiveFilters.state,
                    start_date: definitiveFilters.start_date,
                    end_date: definitiveFilters.end_date,
                    sort_type: definitiveFilters.sort_type,

                    number_pages: pagenum,
                    number_items: this.pagination.itemsPerPage,
                    sortBy: this.sortBy,
                    sortType: this.sortType,
                    category: definitiveFilters.categories
                })
                .then(function () {
                    // self.activeFilters = { ...self.currentFilters };
                    self.activeFilters = JSON.parse(JSON.stringify(self.currentFilters))
                    self.$overlay.hide()
                    self.$emit('resetPageDatatable')
                    if (JSON.stringify(self.activeFilters) != JSON.stringify(self.emptyFilters)) {
                        self.$emit('reset', true)
                    } else {
                        self.$emit('reset', false)
                    }
                })
        }
    },
    mounted() {
        this.activeFilters = { ...this.$store.getters['label/getFiltersActive'] }
        this.currentFilters = { ...this.$store.getters['label/getFiltersActive'] }
        if (!this.currentFilters.location_id) {
            // get first available location
            let locations = this.$store.getters['label/getFilterLocation']()
            // get first object of object
            let firstLocation = Object.values(locations)[0]
            this.currentFilters.location_id = firstLocation
            this.selectedLocationId = firstLocation
        }
        //this.applyFilters()
        // }
    },
    created() {
        if (this.applyFilter) {
            this.currentFilters = { ...this.$store.getters['label/getFiltersActive'] }
            if (!this.currentFilters.location_id) {
                // get first available location
                let locations = this.$store.getters['label/getFilterLocation']()
                // get first object of object
                let firstLocation = Object.values(locations)[0]
                this.currentFilters.location_id = firstLocation
                this.selectedLocationId = firstLocation
            }
            this.currentFilters.sort_type = 'expiration_date'
            if (this.currentFilters.location_id) {
                this.applyFilters()
            }
        }
    },
    watch: {
        currentFilters: {
            handler: function (val, oldVal) {
                this.keyLocationFilter = typeof val.location_id !== 'undefined' ? val.location_id.id : false
                if (JSON.stringify(this.currentFilters) != JSON.stringify(this.activeFilters)) {
                    this.changesDetected = true
                } else {
                    this.changesDetected = false
                }
            },
            deep: true
        },
        optionsCategories: {
            handler: function (val, oldVal) {
                if (val) {
                    if (!this.currentFilters.location_id) {
                        // get first available location
                        let locations = this.$store.getters['label/getFilterLocation']()
                        // get first object of object
                        let firstLocation = Object.values(locations)[0]
                        this.currentFilters.location_id = firstLocation
                        this.emptyFilters.location_id = firstLocation
                        this.applyFilters()
                    }
                }
            },
            deep: true
        },
        selectedLocationId: {
            handler: function (val) {
                if (typeof val !== 'undefined') {
                    this.currentFilters.location_id = val
                }
            }
        }
        /* sortType: {
            handler: function (val, oldVal) {
                this.handleChangePagination()
            }
        } */
    }
}
</script>

<style lang="scss" scoped>
// Busca en assets :^)
</style>
