<template>
    <div v-if="items && selectedItem" class="dropdown" ref="dropdownRef">
        <div class="dropdown-button-container">
            <button class="dropdown-button" @click="toggleDropdown">{{ selectedItem.name }}</button>
        </div>
        <div class="dropdown-list-container" v-if="isOpen">
            <ul class="dropdown-list" tabindex="0">
                <li v-for="item in items" @click="handleItemSelected(item)">{{ itemName(item) }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownMenu',
    props: {
        items: { type: Array },
        selectedItem: { type: Object }
    },
    data() {
        return {
            isOpen: false,
            searchIsVisible: false
        }
    },
    computed: {
        selectedItemName() {
            if (this.selectedItem) {
                return this.selectedItem.name
            } else return ''
        }
    },
    watch: {},
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen
        },

        handleItemSelected(clickedItem) {
            this.$emit('on-item-selected', clickedItem)

            this.closeDropdown()
        },

        closeDropdown() {
            this.isOpen = false
            this.searchValue = ''
            this.filteredItems = []
        },

        checkClickOutside(e) {
            const dropdown = this.$refs.dropdownRef
            const target = e.target

            if (dropdown && dropdown !== target && !dropdown.contains(target)) {
                this.closeDropdown()
            }
        },

        itemName(item) {
            if (item.hasOwnProperty('name')) {
                return item.name
            }
        }
    },
    created() {
        document.addEventListener('click', this.checkClickOutside)
    },
    destroyed() {
        document.removeEventListener('click', this.checkClickOutside)
    }
}
</script>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    display: inline-block;
    // width: 291px;
    min-width: 180px;
    max-width: fit-content;
    height: 100%;
    min-height: 32px;
    max-height: 100%;
}

.dropdown-button-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.dropdown-button {
    position: relative;
    @include font-size('sm');
    color: $color-black;
    background-image: img('down_caret_neutro_s90.svg');
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: 94%;
    background-color: $color-white;
    border: none;
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0px 10px;
    margin: 0 !important;
    text-align: start;
    font-family: $text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 1.2em;
    border: 1px solid $color-neutral-300;
}

.dropdown-list-container {
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 600px;
    overflow-y: scroll;
    background-color: $color-white;
    list-style: none;
    margin-top: 3px;
    width: 100%;
    z-index: 20;
    border-radius: 5px;
    border: 1px solid $color-neutral-200;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.dropdown-list {
    @include font-size('sm');
    display: flex;
    flex-direction: column;
    padding: 0;
}

.dropdown-list li {
    color: $color-black;
    padding: 0.5em 1em 0.5em 2em;
    cursor: pointer;
    transition: background-color 0.2s;
    font-family: $text;
    color: $color-neutral-600;
}

.dropdown-list li:hover {
    background-color: $color-neutral-200;
}
</style>
