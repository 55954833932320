<template>
    <div id="content" class="traceability-label">
        <div class="templates-top-bar-actions">
            <div class="left-menu-container">
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                <DropdownMenu v-if="isMobile" :items="dropdownMenuPages" :selectedItem="selectedPage" @on-item-selected="(page) => changeTool(page)" />
            </div>
            <div>
                <Button bType="back" v-if="preview" :bCallback="goBack" />
                <!-- <Button bType="file" v-else :bCallback="downloadExcel" :bLabel="$tc('supervise.checklists.table_headers.download_excel', 1)" /> -->
                <template>
                    <Button v-if="isMobile" bType="downloadFileIcon" :bCallback="downloadExcel" />
                    <Button v-else bType="file" :bCallback="downloadExcel" :bLabel="$tc('supervise.checklists.table_headers.download_excel', 1)" />
                </template>
            </div>
        </div>
        <div class="preview" v-if="preview">
            <Preview :data="preview" :maximized="true"></Preview>
        </div>

        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }" v-show="!preview">
            <!-- SIDEBAR FILTERS -->
            <div class="sidebar-filters" :class="{ 'empty-results': items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <FilterLabel
                    :key="filterKey"
                    ref="filterLabel"
                    :pagination="pagination"
                    :sortBy="sortBy"
                    :applyFilter="applyFilter"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    :toolTypeAvaible="toolTypeAvaible"
                    @reset="showButtonReset"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @filtersApplied="closeSidebarOnSmallScreens"
                />
            </div>
            <!-- TABLE -->
            <div class="data-table" :class="{ 'empty-results': items.length == 0 }">
                <v-data-table
                    :update:sort-by="sortTable()"
                    v-if="items"
                    :headers="headers"
                    fixed-header
                    :items="items"
                    sort-by="date"
                    :sort-desc="true"
                    :single-select="singleSelect"
                    hide-default-footer
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :options.sync="pagination"
                    @click:row="showPreview"
                    :custom-sort="customSort"
                >
                    <template #item.reference="item">
                        <div class="title" style="display: flex">
                            <!-- <img style="width: auto; max-height: 55px" v-if="products[item.item.reference] != 'false'" class="product-icon" :src="products[item.item.reference].image" /> -->
                            <div
                                class="icon"
                                v-if="products[item.item.reference] && products[item.item.reference].image"
                                :style="{
                                    backgroundImage: 'url(' + products[item.item.reference].image + ')'
                                }"
                            ></div>
                            <div class="icon" v-else></div>
                            <span class="name">{{ products[item.item.reference] ? products[item.item.reference].name : '' }}</span>
                        </div>
                    </template>
                    <template #item.state="item">
                        <Tags v-if="item.item.state" :tags="stateTags(item.item.state, item.item.type_string)" nameTag="name" value="id"></Tags>
                    </template>
                    <template #item.category="item">
                        <Tags v-if="item.item.category" :tags="categoryTags(item.item.category)" nameTag="name" value="id"></Tags>
                    </template>
                    <template #item.date="item">
                        <div class="date">
                            {{ formatDate(item.item.date) }}
                        </div>
                    </template>
                    <template #item.end_date="item">
                        <div
                            class="date secondary"
                            :class="{ isExpired: isExpiredDate(formatDate(item.item.end_date), formatDate(item.item.date)) }"
                            v-html="formatDate(item.item.end_date)"
                        ></div>
                    </template>

                    <template #item.primary="item">
                        <div class="date primary" v-html="item.item.primary"></div>
                    </template>

                    <template v-slot:no-data>
                        <div class="andy-datatable-empty" v-if="itemsLoaded">
                            <EmptyTable
                                buttonClass="btn-action"
                                @buttonAction="resetFilter"
                                buttonType="clearFilters"
                                :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                                :title="$t('empty_table.users_title_supervise')"
                                type="supervise"
                                :description="$t('empty_table.issues_empty_filter')"
                            ></EmptyTable>
                        </div>
                    </template>
                </v-data-table>
                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select :items="rowsPerPage" @change="changedItemPerPage = true" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Preview from '@/components/domain/label/preview.vue'
import FilterLabel from '@/components/domain/label/supervise/traceabilityFilter'
import EmptyTable from '@/components/ui/EmptyTable'
import DropdownMenu from '@/components/ui/DropdownMenu.vue'

export default {
    components: {
        FilterLabel,
        EmptyTable,
        Preview,
        DropdownMenu
    },
    name: 'SuperviseTraceability',
    data() {
        return {
            showReset: false,
            noChanges: false,
            maxQuantity: 0,

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 50
            },
            rowsPerPage: [20, 50, 100],
            pageCount: 0,
            maxPageVisibles: 10,

            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: 'expiration_date',
            changePagination: false,
            changedItemPerPage: false,
            applyFilter: false,
            preview: false,
            recepcion: false,
            filtersSidebarIsHidden: false,

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        toolTypeAvaible() {
            return this.$store.state.login.config.tools.includes(16);
        },
        headers() {
            const headers = [
                {
                    text: this.$t('supervise.label.table_headers.product'),
                    value: 'reference',
                    align: 'start',
                    sortable: true,
                    width: '25%',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.states'),
                    value: 'state',
                    align: 'center',
                    sortable: true,
                    width: '125px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.quantity'),
                    value: 'quantity',
                    align: 'center',
                    sortable: true,
                    width: '50px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.print_date'),
                    value: 'date',
                    align: 'left',
                    sortable: true,
                    width: '150px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.date'),
                    value: 'end_date',
                    align: 'left',
                    sortable: true,
                    width: '200px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.primary'),
                    value: 'primary',
                    align: 'left',
                    sortable: false,
                    width: '300px',
                    class: 'header-table'
                },
                {
                    text: this.$t('supervise.label.table_headers.batch'),
                    value: 'batch',
                    align: 'left',
                    sortable: false,
                    width: '150px',
                    class: 'header-table'
                }
            ]

            if (this.recepcion) {
                const newPosition = 5
                const newElement = {
                    text: this.$t('supervise.label.table_headers.recepcion'),
                    value: 'recepcion',
                    align: 'left',
                    sortable: false,
                    width: '150px',
                    class: 'header-table'
                }
                const removeItem = 0 // don't replace element, only move
                headers.splice(newPosition, removeItem, newElement)
            }

            return headers
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            const numItems = this.$store.getters['label/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['label/getNumItems']
        },
        items() {
            this.recepcion = false
            const self = this
            let labels = Object.values(this.$store.getters['label/getListTraceability']).length >= 1 ? this.$store.getters['label/getListTraceability'] : []
            labels.forEach(function (label) {
                label.batch = ''
                label.primary = false

                if (label.elaborate != '') {
                    label.primary = label.elaborate
                    label.recepcion = ''
                } else {
                    let match = label.data.match(/"lote":"([^"]*)"/)
                    if (match && match.length > 1) {
                        label.batch = match[1]
                    }

                    match = label.data.match(/"caducidad":"([^"]*)"/)
                    if (match && match.length > 1) {
                        label.primary = match[1].replaceAll('\\', '')
                        const isNumeric = /^-?\d+$/.test(label.primary)

                        if (isNumeric) {
                            const date = new Date(label.primary * 1000)
                            const day = String(date.getDate()).padStart(2, '0')
                            const month = String(date.getMonth() + 1).padStart(2, '0')
                            const year = String(date.getFullYear())
                            const formattedDate = `${day}/${month}/${year}`
                            label.primary = `${formattedDate}`
                        } else {
                            label.primary = label.primary.split(' ')[0]
                        }
                    }

                    // recepción
                    if (parseInt(label.recepcion) === 1) {
                        self.recepcion = true
                        label.recepcion = label.primary
                        label.primary = ''
                    } else {
                        label.recepcion = ''
                    }
                }
            })
            return labels
        },
        itemsLoaded() {
            return this.$store.getters['label/getItemsLoaded']
        },
        statesLabels() {
            return this.$store.getters['label/getStates']()
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        products() {
            return this.$store.getters['label/getLabels']()
        },
        categories() {
            return this.$store.getters['label/getCategoriesForm']()
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            return this.windowWidth <= 1023
        },
        dropdownMenuPages() {
            const pages = [
                { id: 'label', path: '/supervise/label', name: this.$tc('tools.supervise_sub.labels_title') },
                { id: 'traceability', path: '/traceability', name: this.$tc('tools.supervise_sub.traceability_title') }
            ]
            return pages
        },
        selectedPage() {
            const currentRoute = this.$route.path

            const selectedPage = this.dropdownMenuPages.find((page) => page.path === currentRoute)

            return selectedPage
        }
    },
    methods: {
        setTypeState(state) {
            let stateType = ''
            if (state === '1') {
                stateType = i18n.t('timers_product.label')
            } else if (state === '2') {
                stateType = i18n.t('timers_product.digitalTimer')
            } else {
                stateType = i18n.t('timers_product.both')
            }
            return stateType.trim()
        },
        sortTable() {
            this.sortBy = this.pagination.sortBy && this.pagination.sortBy.length > 0 ? this.pagination.sortBy : false
            this.sortType =
                this.pagination.sortBy && this.pagination.sortBy.length > 0
                    ? this.pagination.sortDesc.length > 0 && this.pagination.sortDesc[0]
                        ? 'desc'
                        : 'asc'
                    : false
        },
        goBack() {
            this.preview = false
        },
        showPreview(item) {
            // this.preview = JSON.parse(item.preview)
            this.previewLabel(item)
        },
        previewLabel(label) {
            let self = this
            let format = this.$store.getters['label/getTemplates'](label.printer_label_config_id)
            let values = JSON.parse(format.data)

            if (label.preview) {
                try {
                    let params = JSON.parse(label.data)
                    this.preview = JSON.parse(label.preview)

                    let oLocation = this.$store.getters['loginUser/getLocalLocation']
                    params['account'] = oLocation.account_name
                    params['location'] = oLocation.name
                    params['quantity'] = label.quantity
                    params['city'] = oLocation.city
                    params['address'] = oLocation.address
                    params['loc_state'] = oLocation.state
                    params['cp'] = oLocation.cp

                    for (let key in this.preview.fields) {
                        let field = this.preview.fields[key]
                        let value = typeof field.f_id !== 'undefined' ? values[field.f_id] : field.value
                        const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                        let m
                        let aux_value = value
                        while ((m = regex.exec(value)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++
                            }

                            // The result can be accessed through the `m`variable.
                            m.forEach((match, groupIndex) => {
                                let m_index = match.replace(/[\{\}\[\]]/gi, '')
                                let name = this.$t('label.preview.' + m_index)
                                if (name.indexOf('label.preview.') != -1) {
                                    name = m_index
                                }

                                if (!Object.keys(params).includes(m_index)) {
                                    const regex2 = /(_d[0-9]|_day|_timestamp|_time)/gi
                                    let n
                                    while ((n = regex2.exec(m_index)) !== null) {
                                        // This is necessary to avoid infinite loops with zero-width matches
                                        if (n.m_index === regex2.lastIndex) {
                                            regex2.lastIndex++
                                        }

                                        // The result can be accessed through the `m`-letiable.
                                        n.forEach((match2, groupIndex) => {
                                            let keyValue = m_index.replace(match2, '')
                                            if (Object.keys(params).includes(keyValue)) {
                                                let d = moment(params[keyValue], 'DD/MM/YYYY HH:mm')
                                                if (!d.isValid()) {
                                                    d = moment(parseInt(params[keyValue]) * 1000)
                                                }
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue + '_timestamp'] = parseInt(d.valueOf() / 1000).toString()
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            } else if (Object.keys(params).includes(keyValue + '_timestamp')) {
                                                let d = moment(parseInt(params[keyValue + '_timestamp']) * 1000)
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue] = d.format('DD/MM/YYYY HH:mm')
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            }
                                        })
                                    }

                                    if (!Object.keys(params).includes(m_index)) {
                                        params[m_index] = ''
                                    }
                                }

                                if (m_index != '' && Object.keys(params).includes(m_index)) {
                                    aux_value = aux_value.replace(match, params[m_index])
                                }
                            })
                        }
                        self.preview.fields[key].value = aux_value
                    }
                    this.$snackbar.close()

                    this.print_date = params.print_date
                } catch (e) {
                    // this.$snackbar.error({ message: this.$t('hola'), closeable: true })
                }
            } else {
                // this.$snackbar.error({ message: this.$t('label.preview.invalid'), closeable: true })
            }
        },
        formatDate(val) {
            const formats = ['DD/MM/YYYY HH:mm', 'YYYY-MM-DD HH:mm:ss']
            for (let format of formats) {
                if (moment(val, format, true).isValid()) {
                    return moment(val, format).format('DD/MM/YYYY HH:mm')
                }
            }
            return val
        },
        isExpiredDate(dateString, dateStartString) {
            if (!moment(dateString, 'DD/MM/YYYY HH:mm').isValid()) {
                const datePart = moment(dateStartString, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                dateString = `${datePart} 23:59`
            }

            const [datePart, timePart] = dateString.split(' ')
            const [day, month, year] = datePart.split('/')
            const [hours, minutes] = timePart.split(':')

            // Months in JavaScript's Date object are zero-based, so we subtract 1 from the month
            const inputDate = new Date(year, month - 1, day, hours, minutes)
            const currentDate = new Date()

            return inputDate < currentDate
        },
        resetPagination() {
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        resetFilter() {
            this.$refs.filterLabel.resetFilters()
            this.showReset = false
        },
        stateTags(id, type) {
            let result = []
            if (id) {
                const label = this.statesLabels[id]
                const timer_state = type === 'timer' ? 'active' : ''
                const label_state = type === 'label' ? 'active' : ''
                let typeIcon = ''
                if(this.toolTypeAvaible) {
                    typeIcon = `<div class="type"><div class="timer-tag ${timer_state}"></div> <div class="label-tag ${label_state}"></div></div>`
                }
                result.push({
                    id: label.id,
                    name: label.name + '' + typeIcon,
                    tooltipMsg: type === 'timer' ? i18n.t('timers_product.digitalTimer') : i18n.t('timers_product.label')
                })
            }
            return result
        },
        categoryTags(id) {
            let result = []
            if (id) {
                result.push(this.categories[id])
            }
            return result
        },
        showButtonReset(reset) {
            this.showReset = reset
        },
        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            let container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        load() {
            let self = this
            self.$overlay.show()
            this.selected = []
            this.$store.dispatch('label/loadFilters', {}).then(function () {
                if (Object.values(self.categories).length === 0) {
                    self.$store.dispatch('label/loadCategoriesForm', {}).then(function () {
                        //self.$refs.filterLabel.applyFilters()
                        self.$overlay.hide()
                    })
                }
            })
            self.applyFilter = true
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },
        downloadExcel() {
            this.$overlay.show()
            let self = this

            this.$store
                .dispatch('label/downloadTracabilityExcel', { page: 'traceability' })
                .then((file) => {
                    let filename = file.split('/').pop()
                    let link = document.createElement('a')
                    link.download = filename
                    link.href = file
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    self.$overlay.hide()
                })
                .catch((error) => {
                    console.error(error)
                    self.$overlay.hide()
                })
        },
        customSort(items, index, isDesc) {
            const self = this
            const column = index[0]
            switch (column) {
                case 'count':
                    return items
                    break
                case 'reference':
                    return items.sort(function (itemA, itemB) {
                        const productAName = self.products[itemA.reference]
                        const productBName = self.products[itemB.reference]

                        if (isDesc) return productAName > productBName ? -1 : 1
                        return productAName < productBName ? -1 : 1
                    })
                    break
                case 'state':
                    return items.sort(function (itemA, itemB) {
                        const productAState = self.statesLabels[itemA.state]
                        const productBState = self.statesLabels[itemB.state]

                        if (isDesc) return productAState > productBState ? -1 : 1
                        return productAState < productBState ? -1 : 1
                    })
                    break
                case 'quantity':
                    return items.sort(function (itemA, itemB) {
                        if (isDesc) return itemA.quantity > itemB.quantity ? -1 : 1
                        return itemA.quantity < itemB.quantity ? -1 : 1
                    })
                    break
                case 'date':
                    return items.sort(function (itemA, itemB) {
                        const itemADate = new Date(itemA.date)
                        const itemBDate = new Date(itemB.date)
                        if (isDesc) return itemADate > itemBDate ? -1 : 1
                        return itemADate < itemBDate ? -1 : 1
                    })
                    break
                case 'end_date':
                    return items.sort(function (itemA, itemB) {
                        const itemADate = new Date(itemA.end_date)
                        const itemBDate = new Date(itemB.end_date)
                        if (isDesc) return itemADate > itemBDate ? -1 : 1
                        return itemADate < itemBDate ? -1 : 1
                    })
                    break
            }
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('SuperviseTraceabilitySidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        // setFiltersSidebarIsHiddenValue() {
        //     const storedValue = localStorage.getItem('SuperviseTraceabilitySidebarIsHiddenValue')
        //     this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        // },
        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseTraceabilitySidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth
        },
        closeSidebarOnSmallScreens() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            }
        },
        changeTool(page) {
            const currentRoute = this.$route.path

            if (currentRoute !== page.path) {
                this.$router.push(page.path)
            }
        }
    },
    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterLabel.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterLabel.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseTraceabilitySidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },
    created() {
        this.load()

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.setInitialFiltersSidebarIsHiddenValue()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.traceability-label {
    .templates-top-bar-actions {
        padding-bottom: 4px !important;
    }

    .preview {
        @include display-flex();
        @include justify-content(center);
        width: 100%;
        height: auto;
        padding: 80px;
    }

    .left-menu-container {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        height: 32px;
    }

    .supervise-container {
        .data-table {
            .v-data-table,
            .v-data-table table {
                td {
                    height: 2.5rem !important;
                }
                .icon {
                    @include background($image: img('no_icon_light.svg'));
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    margin-right: 10px;
                    float: left;
                }
                .name {
                    padding-top: 5px;
                    display: inline-block;
                }
                .type {
                    padding-top: 5px;
                    display: inline-block;
                }
                .date {
                    font-family: $text;
                    font-size: 14px;

                    &.secondary {
                        &:before {
                            background: $color-primary-500;
                            content: ' ';
                            width: 10px;
                            height: 10px;
                            margin-right: 5px;
                            display: inline-block;
                            border-radius: 100%;
                        }

                        &.isExpired {
                            // font-family: $text-bold;
                            // color: $color-error-500;

                            &:before {
                                background: $color-error-500;
                            }
                        }
                    }
                }

                .score {
                    .bar {
                        height: 5px;
                        border-radius: 10px;
                        width: 100%;
                        background: #ddd;
                        margin-top: 10px;

                        .progress {
                            @include background($color: $color-primary-500);
                            border-radius: 10px;
                            height: 5px;
                        }
                    }
                    .number {
                        @include font-size(20px);
                        font-weight: bold;
                    }
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>

<style lang="scss">
#content.traceability-label {
    .data-table {
        .v-data-table,
        .v-data-table table {
            td {
                height: 3rem !important;
            }
            .elaborate {
                font-family: $text;
                display: block;
                font-size: 14px;
                padding: 1px 0;
            }

            .date {
                &.primary {
                    padding: 5px 0;
                }
            }
        }
    }
}
</style>
