var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('assets.filter.filter_data')))]),_c('div',{staticClass:"filter-items"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('user.filters.search_by')))]),(Object.values(_vm.optionsLocations))?_c('v-autocomplete',{staticClass:"select",class:{
                    filtered:
                        _vm.currentFilters.location_id &&
                        typeof _vm.currentFilters.location_id !== 'undefined' &&
                        Object.values(_vm.currentFilters.location_id).length > 0,
                    changed:
                        JSON.stringify(_vm.currentFilters.location_id) != JSON.stringify(_vm.activeFilters.location_id) &&
                        Object.values(_vm.currentFilters.location_id).length > 0
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsLocations),"item-text":"name","item-value":"id","attach":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}],null,false,2422558294),model:{value:(_vm.selectedLocationId),callback:function ($$v) {_vm.selectedLocationId=$$v},expression:"selectedLocationId"}}):_vm._e(),_c('Tags',{key:_vm.keyLocationFilter,attrs:{"tags":[_vm.currentFilters.location_id],"backImage":'location_inactive_s30.svg',"nameTag":'name',"value":"id"}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('notes.search')))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.currentFilters.name),expression:"currentFilters.name",modifiers:{"trim":true}}],staticClass:"input",class:{
                    filtered: _vm.currentFilters.name && Object.values(_vm.currentFilters.name).length > 0,
                    changed: _vm.currentFilters.name && _vm.currentFilters.name != _vm.activeFilters.name && Object.values(_vm.currentFilters.name).length > 0
                },attrs:{"hide-details":"","type":"text"},domProps:{"value":(_vm.currentFilters.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.applyFilters()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('product.assigned_states')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.state && Object.values(_vm.currentFilters.state).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.state) != JSON.stringify(_vm.activeFilters.state)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsStates),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.state),callback:function ($$v) {_vm.$set(_vm.currentFilters, "state", $$v)},expression:"currentFilters.state"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.state,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('product.assigned_categories')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.categories && Object.values(_vm.currentFilters.categories).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.categories) != JSON.stringify(_vm.activeFilters.categories)
                },attrs:{"hide-details":"","items":Object.values(_vm.optionsCategories),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"placeholder":_vm.$t('assets.filter.filter_by'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}]),model:{value:(_vm.currentFilters.categories),callback:function ($$v) {_vm.$set(_vm.currentFilters, "categories", $$v)},expression:"currentFilters.categories"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.categories,"nameTag":'name',"value":"id","removable":true}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.tracebility.table_headers.sort_type')))]),_c('v-select',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.sort_type,
                    changed: _vm.currentFilters.sort_type != _vm.activeFilters.sort_type
                },attrs:{"hide-details":"","items":_vm.optionsSortType,"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},model:{value:(_vm.currentFilters.sort_type),callback:function ($$v) {_vm.$set(_vm.currentFilters, "sort_type", $$v)},expression:"currentFilters.sort_type"}})],1),_c('div',{staticClass:"filter date"},[_c('label',[_vm._v(_vm._s(_vm.$t('supervise.checklists.table_headers.date')))]),_c('v-select',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.type_date,
                    changed: JSON.stringify(_vm.currentFilters.type_date) != JSON.stringify(_vm.activeFilters.type_date)
                },attrs:{"hide-details":"","items":_vm.optionsDate,"item-text":"name","item-value":"value","dense":"","no-data-text":_vm.$t('supervise.filter.no_results')},on:{"change":function($event){_vm.currentFilters.type_date == 'custom' ? (_vm.currentFilters.date = {}) : true}},model:{value:(_vm.currentFilters.type_date),callback:function ($$v) {_vm.$set(_vm.currentFilters, "type_date", $$v)},expression:"currentFilters.type_date"}}),(_vm.currentFilters.type_date == 'custom')?_c('div',{staticClass:"custom"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.from'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.start_date),expression:"currentFilters.date.start_date"}],staticClass:"date-input start",attrs:{"max":_vm.minDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.start_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "start_date", $event.target.value)}}}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t('assets.filter.to'))+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFilters.date.end_date),expression:"currentFilters.date.end_date"}],staticClass:"date-input end",attrs:{"min":_vm.maxDate,"type":"date"},domProps:{"value":(_vm.currentFilters.date.end_date)},on:{"change":function($event){return _vm.calcDate()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.currentFilters.date, "end_date", $event.target.value)}}})]):_vm._e()],1),(_vm.toolTypeAvaible)?_c('div',{staticClass:"filter"},[_c('label',[_vm._v(_vm._s(_vm.$t('timers_product.title')))]),_c('v-autocomplete',{staticClass:"select",class:{
                    filtered: _vm.currentFilters.types && Object.values(_vm.currentFilters.types).length > 0,
                    changed: JSON.stringify(_vm.currentFilters.types) != JSON.stringify(_vm.activeFilters.types)
                },attrs:{"hide-details":"","items":Object.values(_vm.typesFilters),"placeholder":_vm.$t('assets.filter.filter_by'),"item-text":"name","item-value":"id","multiple":"","dense":"","no-data-text":_vm.$t('supervise.filter.no_results'),"return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [_vm._v("\n                    "+_vm._s(index == 0 ? '' : '')+"\n                ")]}}],null,false,2422558294),model:{value:(_vm.currentFilters.types),callback:function ($$v) {_vm.$set(_vm.currentFilters, "types", $$v)},expression:"currentFilters.types"}}),_c('Tags',{attrs:{"tags":_vm.currentFilters.types,"nameTag":'name',"value":"id","removable":true}})],1):_vm._e()]),_c('FilterActions',{attrs:{"showClearFiltersButton":_vm.showClearFiltersButton,"clearFiltersCallback":_vm.resetFilters,"filterCallback":_vm.applyFilters,"changesDetected":_vm.changesDetected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }